body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0B0C0D;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  overflow-x: hidden;
}

.indentation {
  padding-left: 160px;
}
.hamburguer-react {
  z-index: 3!important;
}

@font-face {
 font-family: 'Helvetica-Bold';
 src: url('./fonts/HelveticaNeueLTStd-Bold.otf') format('opentype');
}

@font-face {
 font-family: 'Helvetica';
 src: url('./fonts/HelveticaNeueLTStd-Roman.otf') format('opentype');
}

@font-face {
 font-family: 'Pixel';
 src: url('./fonts/NeuePixelGrotesk.ttf') format('opentype');
}
